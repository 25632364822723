<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar'">
    <button mat-icon-button *ngIf="!opened" (click)="open()">
        <mat-icon [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
    </button>
    <div class="absolute inset-0 flex items-center flex-shrink-0 z-99 bg-card" [ngClass]="{'bg-green-900': thisUser?.role === 'admin'}" *ngIf="opened" @slideInTop @slideOutTop>
        <mat-icon class="absolute ml-6 sm:ml-8" [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
        <input class="w-full h-full px-16 sm:px-18" [formControl]="searchControl" [matAutocomplete]="matAutocomplete"
            [placeholder]="'Search for a user'" (keydown)="onKeydown($event)" #barSearchInput>
        <mat-autocomplete class="max-h-128 sm:px-2 border-t rounded-b shadow-md" [disableRipple]="true" autoActiveFirstOption
            #matAutocomplete="matAutocomplete">
            <mat-option class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                *ngIf="resultSets && !resultSets.length">
                No results found!
            </mat-option>
            <ng-container *ngFor="let resultSet of resultSets; trackBy: trackByFn">
                <mat-optgroup class="flex items-center mt-2 px-2">
                    <span
                        class="text-sm font-semibold tracking-wider text-secondary">{{resultSet.label.toUpperCase()}}</span>
                </mat-optgroup>
                <ng-container *ngFor="let result of resultSet.results; trackBy: trackByFn">
                    <mat-option
                        class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
                        (onSelectionChange)="onSelectUser(result, $event)">
                        <!-- Contacts -->
                        <ng-container *ngIf="resultSet.id === 'users'">
                            <ng-container
                                *ngTemplateOutlet="contactResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                        <!-- Pages -->
                        <ng-container *ngIf="resultSet.id === 'pages'">
                            <ng-container *ngTemplateOutlet="pageResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                        <!-- Tasks -->
                        <ng-container *ngIf="resultSet.id === 'tasks'">
                            <ng-container *ngTemplateOutlet="taskResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </ng-container>
        </mat-autocomplete>
        <button class="absolute top-1/2 right-5 sm:right-7 flex-shrink-0 w-10 h-10 -mt-5" mat-icon-button
            (click)="close()">
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
</ng-container>

<!-- Basic search -->
<!-- <ng-container *ngIf="appearance === 'basic'">
    <div class="w-full sm:min-w-80">
        <mat-form-field class="fuse-mat-no-subscript w-full">
            <mat-icon matPrefix [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
            <input matInput [formControl]="searchControl" [matAutocomplete]="matAutocomplete"
                [placeholder]="'Search for a user'" (keydown)="onKeydown($event)">
        </mat-form-field>
        <mat-autocomplete class="max-h-128 mt-1 rounded" [disableRipple]="true" #matAutocomplete="matAutocomplete" autoActiveFirstOption>
            <mat-option class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                *ngIf="resultSets && !resultSets.length">
                No results found!
            </mat-option>
            <ng-container *ngFor="let resultSet of resultSets; trackBy: trackByFn">
                <mat-optgroup class="flex items-center mt-2 px-2">
                    <span
                        class="text-sm font-semibold tracking-wider text-secondary">{{resultSet.label.toUpperCase()}}</span>
                </mat-optgroup>
                <ng-container *ngFor="let result of resultSet.results; let i = index; trackBy: trackByFn">
                    <mat-option
                        class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover search-result">
                        <ng-container *ngIf="resultSet.id === 'users'">
                            <ng-container
                                *ngTemplateOutlet="contactResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="resultSet.id === 'pages'">
                            <ng-container *ngTemplateOutlet="pageResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="resultSet.id === 'tasks'">
                            <ng-container *ngTemplateOutlet="taskResult; context: {$implicit: result}"></ng-container>
                        </ng-container>
                    </mat-option>
                </ng-container>
            </ng-container>
        </mat-autocomplete>
    </div>
</ng-container> -->

<!-- Contact result template -->
<ng-template #contactResult let-user>
    <div class="flex items-center w-full justify-between">
        <div class="flex">
            <div
                class="flex flex-shrink-0 items-center justify-center w-9 h-9 rounded-full overflow-hidden bg-primary-100 dark:bg-primary-800">
                <img *ngIf="user.personalInformation.avatar"
                    [src]="platformService.getFileSrc(user.personalInformation.avatar, 'avatar')" draggable="false">
                <mat-icon class="m-0 icon-size-5 text-primary dark:text-primary-400"
                    *ngIf="!user.personalInformation.avatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            </div>
            <div class="ml-3 truncate">
                <div class="flex flex-col text-left items-start leading-tight">
                    <div class="font-semibold text-lg text-left leading-tight">
                        <span>{{user.label}}</span>
                        <span
                        *ngIf="user.role === 'admin' || user.role === 'partner' || user.role === 'label' || user.role === 'marketing'"
                        [ngClass]="{
                            'bg-yellow-200 text-yellow-800 dark:bg-yellow-600 dark:text-yellow-50': user.role === 'admin',
                            'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': user.role === 'partner',
                            'bg-purple-200 text-purple-800 dark:bg-purple-600 dark:text-purple-50': user.role === 'marketing',
                            'bg-sky-200 text-sky-800 dark:bg-sky-600 dark:text-sky-50': user.role === 'label'
                            }"
                        class="inline-flex items-center font-bold text-xs ml-2 px-2.5 py-0.5 rounded-full tracking-wide uppercase">
                        <span class="leading-relaxed whitespace-nowrap">
                            {{user.role}}
                        </span>
                    </span>
                    <span *ngIf="!user.permissions.isEmailConfirmed"
                        class="inline-flex items-center font-bold text-xs ml-2 px-2.5 py-0.5 rounded-full tracking-wide uppercase bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-50">
                        <span class="leading-relaxed whitespace-nowrap">
                            UNVERIFIED
                        </span>
                    </span>
                    <span *ngIf="user.permissions.demoAccount"
                        class="inline-flex items-center font-bold text-xs ml-2 px-2.5 py-0.5 rounded-full tracking-wide uppercase bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-50">
                        <span class="leading-relaxed whitespace-nowrap">
                            DEMO ACCOUNT
                        </span>
                    </span>
                    <span *ngIf="!user.permissions.platformAccess"
                        class="inline-flex items-center font-bold text-xs ml-2 px-2.5 py-0.5 rounded-full tracking-wide uppercase bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50">
                        <span class="leading-relaxed whitespace-nowrap">
                            LOCKED
                        </span>
                    </span>
                    <span *ngIf="!user.permissions.releaseDistribution"
                        class="inline-flex items-center font-bold text-xs ml-2 px-2.5 py-0.5 rounded-full tracking-wide uppercase bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50">
                        <span class="leading-relaxed whitespace-nowrap">
                            NO DISTRIBUTION
                        </span>
                    </span>
                    <span *ngIf="user.permissions.teamMember"
                        class="inline-flex items-center font-bold text-xs ml-2 px-2.5 py-0.5 rounded-full tracking-wide uppercase bg-primary-200 text-primary-800 dark:bg-primary-600 dark:text-primary-50">
                        <span class="leading-relaxed whitespace-nowrap">
                            TEAM MEMBER
                        </span>
                    </span>
                    <span *ngIf="user.marketingDealCount !== undefined && user.marketingDealCount !== '0'"
                        class="inline-flex items-center font-bold text-xs ml-2 px-2.5 py-0.5 rounded-full tracking-wide uppercase bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50">
                        <span class="leading-relaxed whitespace-nowrap">
                            MARKETING DEALS
                        </span>
                    </span>
                </div>
                    <div class="text-hint" [innerText]="user.sublabel"></div>
                </div>
            </div>
        </div>
        <div class="flex flex-row items-center gap-2">
            <div class="flex rounded-full hover:bg-primary-100 p-2" matTooltip="User Management" (click)="goToUserManagement(user, $event)">
                <mat-icon class="m-0 icon-size-6 text-primary dark:text-primary-400"
                    [svgIcon]="'heroicons_outline:user'"></mat-icon>
            </div>
            <div class="flex rounded-full hover:bg-primary-100 p-2" matTooltip="User Releases" (click)="goToReleases(user, $event)">
                <mat-icon class="m-0 icon-size-6 text-primary dark:text-primary-400"
                    [svgIcon]="'heroicons_outline:musical-note'"></mat-icon>
            </div>
            <div class="flex rounded-full hover:bg-primary-100 p-2" matTooltip="User Analytics" (click)="goToAnalytics(user, $event)">
                <mat-icon class="m-0 icon-size-6 text-primary dark:text-primary-400"
                    [svgIcon]="'heroicons_outline:chart-bar'"></mat-icon>
            </div>
            <div class="flex rounded-full hover:bg-primary-100 p-2" matTooltip="Send Notification" (click)="openNotificationModal(user, $event)">
                <mat-icon class="m-0 icon-size-6 text-primary dark:text-primary-400"
                    [svgIcon]="'heroicons_outline:chat-bubble-oval-left-ellipsis'"></mat-icon>
            </div>
            <div class="flex rounded-full hover:bg-primary-100 p-2" matTooltip="Marketing Deals" (click)="goToMarketingDeals(user, $event)">
                <mat-icon class="m-0 icon-size-6 text-primary dark:text-primary-400"
                    [svgIcon]="'heroicons_outline:scale'"></mat-icon>
            </div>
            <div class="flex rounded-full hover:bg-primary-100 p-2" matTooltip="Expenses" (click)="goToExpenses(user, $event)">
                <mat-icon class="m-0 icon-size-6 text-primary dark:text-primary-400"
                    [svgIcon]="'heroicons_outline:currency-euro'"></mat-icon>
            </div>
        </div>
    </div>
</ng-template>

<!-- Page result template -->
<ng-template #pageResult let-result>
    <div class="flex flex-col">
        <div class="truncate leading-normal" [innerHTML]="result.title"></div>
        <div class="truncate leading-normal text-sm text-secondary">
            {{result.link}}
        </div>
    </div>
</ng-template>

<!-- Task result template -->
<ng-template #taskResult let-result>
    <div class="flex items-center">
        <ng-container *ngIf="result.completed">
            <mat-icon class="mr-0 text-primary dark:text-primary-400"
                [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
        </ng-container>
        <ng-container *ngIf="!result.completed">
            <mat-icon class="mr-0 text-hint" [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
        </ng-container>
        <div class="ml-3 truncate leading-normal" [ngClass]="{'line-through text-hint': result.completed}"
            [innerHTML]="result.title"></div>
    </div>
</ng-template>
